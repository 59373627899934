<template>
    <v-row>
        <v-col cols="12">
            <actualizar :showDireccion="true" ref="ActualizarRef" @GetActualizarLista="GetActualizarLista"></actualizar>
        </v-col>
        <v-col cols="12">
            <Lista ref="ListarRef" @GetEditar="GetEditar"></Lista>
        </v-col>
    </v-row>

</template>
<script>
import Actualizar from './componentes/Actualizar.vue'
import Lista from './componentes/Lista.vue'
import { ref } from '@vue/composition-api'
export default {
    components: { Actualizar, Lista },
 
    setup() {
        const ListarRef = ref(null)
        const ActualizarRef = ref(null)

        const datos = ref({
            "id": -1, 
            "descripcion": "",
            "indActivo": true
        }) 

        const GetActualizarLista = (item) => {
             ListarRef.value.cargar(item)
        }
        const GetEditar = (item) => {
            ActualizarRef.value.CargarEditar(item)
        }

        return {
            ListarRef,
            ActualizarRef,
            datos,
            GetActualizarLista,
            GetEditar
        }
    },
}
</script>